/**
 * INITIALIZE AOS
 */

const initializeAOS = () => {
  AOS.init({
    once: true,
    duration: 1300,
    disable: 'mobile'
  });
}

export default  initializeAOS