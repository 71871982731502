import hamburgerToggle from './components/hamburgerToggle'
import mainvisualVideo from './components/mainvisualVideo'
import initializeAOS from './components/initializeAOS'
import scrollTop from './components/scrollTop'
import anchorMenu from './components/anchorMenu'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    hamburgerToggle()
    mainvisualVideo()
    initializeAOS()
    scrollTop()
    anchorMenu()
  },
  false
)
