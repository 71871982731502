/**
 * Toggle top mainvisual
 */

const mainvisualVideo = () => {
  if(screen.width > 1023) {
    window.addEventListener('load', (event) => {
      let image = document.querySelector('.videooverlay');
      image.classList.toggle('header-top__mv-image--play');

      let video = document.querySelector('.header-top__mv-movie').querySelector('iframe');
      console.log(video.contentWindow.document.body.querySelectorAll('a'))
      video.querySelector('.ytp-button').click();
    });
  }
}

export default mainvisualVideo