/**
 * Toggle menu SP
 */

const hamburgerToggle = () => {
  let item = document.querySelectorAll('.nav__hamburger');
  let nav = document.querySelector('.nav');

  for(let i = 0; i < item.length; i++) {
    item[i].addEventListener('click', function() {
      nav.classList.toggle('nav--active');
    });
  }
}

export default hamburgerToggle