/**
 * Scroll back to top
 */
const scrollTop = () => {
  let scroll = document.querySelector('.footer__totop');
  let animationBlock = document.querySelector('.slide');

  function scrolled(ev){
    if(window.pageYOffset > 150) {
      animationBlock.classList.add('viewed');
    } else {
      animationBlock.classList.remove('viewed');
    }
  }

  window.addEventListener('scroll', () => {
    scrolled();
  })

  scroll.addEventListener('click', () => {
    document.body.scrollIntoView({ 
      behavior: 'smooth' 
    })
  })
}

export default scrollTop