/**
 * Toggle active anchor menu
 */

const anchorMenu = () => {
  let link = document.querySelectorAll('.nav__item');
  let active = document.querySelectorAll('.nav__item--active');
  let nav = document.querySelector('.nav');

  for(let item of link) {
    item.addEventListener('click', function() {
      clear();
      item.classList.add("nav__item--active");
      nav.classList.remove('nav--active');
    });
  }

  function clear() {
    for(let item of link) {
      item.classList.remove("nav__item--active");
    }
  }
}

export default anchorMenu